import toastr from 'toastr';
import Swal from 'sweetalert2';
import { facebookShare, initLinkShare, kakaoShare } from '../../components/share';
import API from '../../api';

// 귀찮은거 때려박기

onload(() => {
  // 테마 좋아요 아이콘
  for (const el of document.querySelectorAll('.theme-icon-like')) {
    const id = el.dataset.themeId;
    if (!id) continue;

    el.addEventListener('click', e => {
      if (el.isRequesting) {
        // noty.error('처리 중입니다.');
        return;
      }
      el.isRequesting = true;

      // 하트 먼저 채우기
      if (el.classList.contains('off')) el.classList.remove('off');
      else el.classList.add('off');

      API.themes.like
        .withId(id)
        .call()
        .then(res => {
          if (res.liked) {
            // noty.success('좋아요 표시 성공!');
            el.classList.remove('off');
          } else {
            el.classList.add('off');
            // noty.success('좋아요 표시 해제 성공!');
          }
        })
        .catch(err => {
          console.log(err);
          switch(err.status) {
            case 401:
              toastr.error('로그인이 필요한 서비스입니다.');
              break;
            default:
              toastr.error('좋아요 표시에 실패하였습니다!');
          }

          if (el.classList.contains('off')) el.classList.remove('off');
          else el.classList.add('off');
        })
        .finally(() => {
          el.isRequesting = false;
        });
    });
  }

  // 공유하기 아이콘
  const template = $('#themes-share-modal').html();
  document.querySelectorAll('.share').forEach(e => {
    const data = e.dataset;

    e.addEventListener('click', evt => {
      Swal.fire({
        showConfirmButton: false,
        html: template
      });

      const swalContent = document.querySelector('#swal2-content');
      if (!swalContent) return;

      const facebook = swalContent.querySelector('.facebook');
      facebook.addEventListener('click', ev => {
        facebookShare(data);
      });

      const kakao = swalContent.querySelector('.kakaotalk');
      kakao.addEventListener('click', ev => {
        kakaoShare(data);
      });

      switch(appData().native) {
        case "iOS":
          if (!window.webkit?.messageHandlers?.kakaoShare)
            kakao.classList.add('hide');
          break;
      }

      const link = swalContent.querySelector('.link');
      link.dataset.clipboardText = data.href;
      initLinkShare(link);
    });

    // console.log(window.webkit.messageHandlers.setToken(navigator.userAgent))
  });
})