onload(() => {
  const playFooter = document.getElementById('play-footer');

  function addScrollEvent() {
    window.addEventListener('scroll', function(e) {
      if (!playFooter) return;
      playFooter.classList.add("hide");
      
      setTimeout(() => {
        playFooter.style.display = "none";
      }, 500);
    }, { once: true });
  }

  // 스크롤 내린 상태에서 새로고침 때 바로 이벤트가 호출되어 500 텀 주기
  if (playFooter) setTimeout(() => { addScrollEvent(); }, 500);
  
  // Title
  const navTitleElement = document.getElementById('nav-title');
  if (!navTitleElement) return;

  if (!window.navTitleY) window.navTitleY = 0;
  let navTitleVisible = false;
  let navTitleFade = true;

  window.addEventListener('scroll', function(e) {
    if (!navTitleFade || !navTitleElement) return;
    toggleNavTitle();
  });

  function toggleNavTitle() {
    const activate = window.scrollY >= window.navTitleY;

    if (activate && !navTitleVisible) {
      navTitleElement.classList.remove('hide');
      navTitleVisible = true;
    } else if (!activate && navTitleVisible) {
      navTitleElement.classList.add('hide');
      navTitleVisible = false;
    }
  }
  toggleNavTitle();
}, {});
